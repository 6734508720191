import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'

import { URL } from '../../constatns'

export const loadMainInfo = async (id) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/main/${id}`)
}

export const loadWorkingShiftInfo = async (workingShiftId, userId) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/working-shift/${workingShiftId}/${userId}`)
}