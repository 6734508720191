import store from '@/store'

export function isSalePriceInProduct (product) {
  return !!Number(product?.salePrice)
}

export function isSalePriceInProductId (productId, products) {
  return !!Number(products[productId]?.salePrice)
}

export function getTitleById (name, module) {
  const list = store.getters[`${module}/getTitleById`]

  if (Array.isArray(name)) {
    return name.reduce((acc, item, itemId) => {
      const d = name.length !== itemId + 1

      acc += `${list[item]}${d ? ', ' : ''}`
      return acc
    }, '')
  }

  return list[name]
}

export function getPriceToFormat (price) {
  return Number(price).toLocaleString('ru')
}

function getDeviceType () {
  const width = window.innerWidth

  if (width < 425) {
    return 'mobile'
  } else if (width > 960) {
    return 'desktop'
  }

  return ''
}

export function isMobile () {
  return getDeviceType() === 'mobile'
}

export function isDesktop () {
  return getDeviceType() === 'desktop'
}

export function getApiUrl () {
  const shopId = localStorage.getItem('shopId')
  const linkName = `VUE_APP_API_URL_${shopId}`

  if (process.env.RUNNING_APP !== 'dev' || !process.env[linkName]) {
    return process.env[linkName]
  }

  return process.env.VUE_APP_API_URL_1
}