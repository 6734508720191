import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "404" */ '../routes/404')
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth" */ '../routes/auth')
  },
  {
    path: '/',
    name: 'main',
    component: () => import(/* webpackChunkName: "main" */ '../routes/main')
  },
  {
    path: '/products',
    name: 'products',
    component: () => import(/* webpackChunkName: "products" */ '../routes/products')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import(/* webpackChunkName: "categories" */ '../routes/categories')
  },
  {
    path: '/online-orders',
    name: 'onlineOrders',
    component: () => import(/* webpackChunkName: "online-orders" */ '../routes/onlineOrders')
  },
  {
    path: '/users',
    name: 'users',
    meta: {
      onlyAdmin: true
    },
    component: () => import(/* webpackChunkName: "users" */ '../routes/users')
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '../routes/sales')
  },
  {
    path: '/sales-users',
    name: 'salesUsers',
    component: () => import(/* webpackChunkName: "sales-users" */ '../routes/salesUsers')
  },
  {
    path: '/sales-statistic',
    name: 'salesStatistic',
    component: () => import(/* webpackChunkName: "sales-statistic" */ '../routes/salesStatistic')
  },
  {
    path: '/cash-operation',
    name: 'cashOperation',
    component: () => import(/* webpackChunkName: "cashOperation" */ '../routes/cashOperation')
  },
  {
    path: '/bar-codes',
    name: 'barCodes',
    component: () => import(/* webpackChunkName: "barCodes" */ '../routes/barCodes')
  },
  {
    path: '/inventory',
    name: 'inventory',
    component: () => import(/* webpackChunkName: "inventory" */ '../routes/inventory')
  },
  {
    path: '/working-shift',
    name: 'workingShift',
    component: () => import(/* webpackChunkName: "workingShift" */ '../routes/workingShift')
  },
  {
    path: '/operating-schedule',
    name: 'operatingSchedule',
    component: () => import(/* webpackChunkName: "operatingSchedule" */ '../routes/operatingSchedule')
  },
  {
    path: '/history',
    name: 'history',
    component: () => import(/* webpackChunkName: "history" */ '../routes/history')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.getters['user/getUserId'] && to.name !== 'auth') {
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    localStorage.removeItem('accessAllowed')
    store.commit('user/clearState')

    next('/auth')
  }

  if ((to.name === 'auth' && store.getters['user/getUserId'])
    || to.meta?.onlyAdmin && !store.getters['user/isAccessAllowed']) {
    next('/')
  }

  next()
})

export default router
