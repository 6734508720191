export default {
  async openModal ({ commit, state }, data) {
    const { openedModals } = state
    let opened = [ ...openedModals ]
    opened.push(data)

    commit('setOpened', opened)
  },
  async closeModal ({ commit, state }, nameModal) {
    if (!nameModal) {
      commit('setOpened', [])

      return
    }

    const { openedModals } = state
    let opened = openedModals.filter(el => el.name !== nameModal)

    commit('setOpened', opened)
  },
}