export default {
  setDataSales (state, payload) {
    if (!payload) {
      return
    }

    state.sales = payload
  },
  setDataSalesToOneMountPrice (state, payload) {
    if (!payload) {
      return
    }

    state.salesToOneMountPrice = payload
  },
  setDataSalesToProducts (state, payload) {
    if (!payload) {
      return
    }

    state.salesToProducts = payload
  },
  setDataPurchasePriseByDate (state, payload) {
    if (!payload) {
      return
    }

    state.purchasePriseByDate = payload
  },
  setDataSumForTypePayByDate (state, payload) {
    if (!payload) {
      return
    }

    state.sumForTypePayByDate = payload
  },
  setIsDataLoadedSales (state, payload) {
    state.isDataLoadedSales = payload
  },
  setIsDataLoadedSalesToOneMountPrice (state, payload) {
    state.isDataLoadedSalesToOneMountPrice = payload
  },
  setIsDataLoadedSalesToProducts (state, payload) {
    state.isDataLoadedSalesToProducts = payload
  },
  setIsDataLoadedPurchasePriseByDate (state, payload) {
    state.isDataLoadedPurchasePriseByDate = payload
  },
  setIsDataLoadedSumForTypePayByDate (state, payload) {
    state.isDataLoadedSumForTypePayByDate = payload
  },
  setLastDataUpdated (state) {
    state.lastDataUpdated = new Date().getTime()
  },
  clearState (state) {
    state.data = []
    state.isDataLoaded = false
    state.lastDateUpdated = 0
  }
}