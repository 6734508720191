import { getTitleById } from '@/helper'
import moment from 'moment'

export default {
  getData (state) {
    return state.data.map((item) => {
      return {
        ...item,
        productsNames: getTitleById(item.products, 'products'),
        createdAt: moment(item.createdAt).format('dd - DD.MM.YYYY HH:mm')
      }
    })
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  }
}