import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { ONE_HOUR } from '@/constants/time'

export default {
  async loadData ({ commit, state }) {
    if (state.lastDateUpdated !== 0 && state.data.length && Date.now() - state.lastDateUpdated < ONE_HOUR) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.loadData() || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async getInfoById ({ commit }, { id }) {
    commit('setIsDataLoaded', false)
    const { data } = await api.getInfo(id) || {}

    if (data?.code === 200) {
      return data.data
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async open ({ commit, dispatch, state }) {
    commit('setIsDataLoaded', false)

    const { data } = await api.open() || {}

    if (data?.code === 200) {
      const newData = state.data
      newData.unshift(data.data)

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async close ({ commit, dispatch, state }) {
    commit('setIsDataLoaded', false)

    const { data } = await api.close() || {}

    if (data?.code === 200) {
      const newData = state.data.map(item => {
        if (item.id === data.data.id) {
          item = {
            ...item,
            ...data.data
          }
        }

        return item
      })

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async remove ({ commit, dispatch, state }, payload) {
    commit('setIsDataLoaded', false)

    const { data } = await api.remove(payload) || {}

    if (data?.code === 200) {
      const newData = state.data.filter(({ id }) => id !== payload )

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  }
}