import actions from './actions'
import mutations from './mutations'
import getters from './getters'
import state from './state'

export default {
  name: 'users',
  namespaced: true,
  actions,
  mutations,
  getters,
  state
}