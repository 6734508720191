import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError, showNotifySuccess } from '@/helper/notify'

export default {
  async sendBugReport ({ dispatch, rootGetters }, payload) {
    const { data } = await api.send({
      userId: rootGetters['user/getUserId'],
      userName: rootGetters['user/getFullName'],
      text: payload
    }) || {}

    if (data?.code === 200) {
      showNotifySuccess('Сообщение отправлено!')
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  }
}