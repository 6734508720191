<template>
  <main
    id="app"
    v-loading="showLoader"
    :class="{ 'overflow-hidden': showLoader }"
  >
    <top-bar
      v-if="isShowTopBar"
      :title="getPageTitle"
    />

    <router-view />

    <modal-render />
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import TopBar from '@/components/bars/top'
import page from '@/mixins/page'

export default {
  name: 'Main',
  mixins: [page],
  components: {
    TopBar,
    ModalRender: () => import('@/components/modals/modal-render'),
  },
  data () {
    return {
      showLoader: false
    }
  },
  watch: {
    '$route.name' () {
      document.title = this.getPageTitle
    }
  },
  computed: {
    ...mapGetters('user', ['isAuth']),
    ...mapGetters('workingShift', ['getOpenedWorkingShift']),
    ...mapGetters('settings', ['getLinks']),
    isShowTopBar () {
      return this.getLinks.length && this.isAuth
    }
  },
  async created () {
    this.$bus.on('start-loader', this.startLoader)
    this.$bus.on('stop-loader', this.stopLoader)

    this.setShopId()
  },
  beforeDestroy() {
    this.$bus.off('start-loader', this.startLoader)
    this.$bus.off('stop-loader', this.stopLoader)
  },
  methods: {
    // isMobile,
    startLoader () {
      this.showLoader = true
      document.getElementsByTagName('html')[0].classList.add('overflow-hidden')
    },
    stopLoader () {
      this.showLoader = false
      document.getElementsByTagName('html')[0].classList.remove('overflow-hidden')
    },
    setShopId () {
      if (!localStorage.getItem('shopId')) {
        localStorage.setItem('shopId', '1')
      }
    }
  }
}
</script>

<style>
@import "assets/styles/main.scss";
</style>
