const MODAL_NAME_PRODUCTS = 'товара'
const MODAL_NAME_CATEGORIES = 'категории'
const MODAL_NAME_ONLINE_ORDERS = 'интернет заказа'
const MODAL_NAME_SALES = 'продажи'
const MODAL_NAME_USERS = 'нового пользователя'
const MODAL_OPERATING_SCHEDULE = 'графика работы'

const PAGE_NAME_MAIN = 'Главная'
const PAGE_NAME_PRODUCTS = 'Товары'
const PAGE_NAME_CATEGORIES = 'Категории товаров'
const PAGE_NAME_ONLINE_ORDERS = 'Интернет заказы'
const PAGE_NAME_USERS = 'Пользователи'
const PAGE_NAME_SALES = 'Продажи'
const PAGE_NAME_SALES_USERS = 'Продажи сотрудников'
const PAGE_NAME_SALES_STATISTIC = 'Статистика продаж'
const PAGE_NAME_BAR_CODES = 'Штрих-коды товаров'
const PAGE_NAME_WORKING_SHIFT = 'Рабочие смены'
const PAGE_NAME_CASH_OPERATION = 'Операции по кассе'
const PAGE_NAME_INVENTORY = 'Инвентаризация'
const PAGE_OPERATING_SCHEDULE = 'График работы'

const NAMES = {
  activePage: {
    'salesUsers': 'sales',
    'salesStatistic': 'sales'
  },
  modalNames: {
    'products': MODAL_NAME_PRODUCTS,
    'categories': MODAL_NAME_CATEGORIES,
    'onlineOrders': MODAL_NAME_ONLINE_ORDERS,
    'sales': MODAL_NAME_SALES,
    'users': MODAL_NAME_USERS,
    'operatingSchedule': MODAL_OPERATING_SCHEDULE
  },
  pageNames: {
    'main': PAGE_NAME_MAIN,
    'products': PAGE_NAME_PRODUCTS,
    'categories': PAGE_NAME_CATEGORIES,
    'onlineOrders': PAGE_NAME_ONLINE_ORDERS,
    'users': PAGE_NAME_USERS,
    'sales': PAGE_NAME_SALES,
    'salesUsers': PAGE_NAME_SALES_USERS,
    'salesStatistic': PAGE_NAME_SALES_STATISTIC,
    'barCodes': PAGE_NAME_BAR_CODES,
    'workingShift': PAGE_NAME_WORKING_SHIFT,
    'cashOperation': PAGE_NAME_CASH_OPERATION,
    'inventory': PAGE_NAME_INVENTORY,
    'operatingSchedule': PAGE_OPERATING_SCHEDULE
  }
}

export default NAMES