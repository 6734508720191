import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { checkRole } from '@/store/user/helper'
import router from '@/router'
import { showNotifyError } from '@/helper/notify'
import { THREE_MINUTES } from '@/constants/time'

export default {
  async loadData ({ commit, getters, state }) {
    if (state.lastDateUpdated !== 0 && state.data.length && Date.now() - state.lastDateUpdated < THREE_MINUTES) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.loadData(getters.getUserId) || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDateUpdated')
      localStorage.setItem('userId', data.data.id)
      localStorage.setItem('accessAllowed', checkRole(data.data.role) ? 'admin' : 'staff')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async auth ({ commit }, payload) {
    commit('setIsDataLoaded', false)

    const { data } = await api.auth(payload) || {}

    if (data?.code === 200) {
      commit('setData', data.data)

      localStorage.setItem('token', data.data.token)
      localStorage.setItem('userId', data.data.id)
      localStorage.setItem('accessAllowed', checkRole(data.data.role) ? 'admin' : 'staff')

      setTimeout(async () => {
        await router.push('/')
      })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async logout ({ commit, getters }) {
    commit('setIsDataLoaded', false)

    const { data } = await api.logout(getters.getUserId) || {}

    if (data?.code === 200) {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('accessAllowed')

      commit('clearState')

      setTimeout(async () => {
        await router.push('/auth')
      })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  }
}