export default {
  // TODO: Оч криво переделать!!!!!
  getData (state, _getters, _rootState, rootGetters) {
    const data = state.data

    const getSalesById = (findId) => rootGetters['sales/getData'].filter(({ id }) => id === findId)[0]
    const getProductsById = (findId) => rootGetters['products/getData'].filter(({ id }) => id === findId)[0]
    const getProductsNames = (products) => products.reduce((acc, product) => {
      acc.push(getProductsById(product).title)

      return acc
    }, [])

    return data.map(item => {
      return {
        ...item,
        productsNames: getProductsNames(getSalesById(item.saleId)?.products || [])
      }
    })
  },
  getLastBalance (state) {
    return state.lastBalance
  }
}