import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { THREE_MINUTES } from '@/constants/time'
import moment from 'moment/moment'

const DEFAULT_DATE = [moment().subtract(1, 'days').format(), moment().format()]

export default {
  async loadData ({ commit, state }, {
    date = DEFAULT_DATE,
    force = false
  } = {}) {
    if (!force
      && state.lastDateUpdated !== 0
      && state.data.length
      && Date.now() - state.lastDateUpdated < THREE_MINUTES
    ) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.loadData(date) || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async getDataById ({ commit }, id) {
    commit('setIsDataLoaded', false)

    const { data } = await api.getDataById(id) || {}

    if (data?.code === 200) {
      commit('setIsDataLoaded', true)

      return data.data
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async getDataByWorkingShift ({ commit }, payload) {
    commit('setIsDataLoaded', false)

    const { workingShiftId } = payload || {}
    const { data } = await api.getDataByWorkingShift(workingShiftId) || {}

    if (data?.code === 200) {
      commit('setIsDataLoaded', true)

      return data.data
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async getDataByOpenedWorkingShift ({ commit, rootGetters }) {
    const workingShiftId = rootGetters['workingShift/getOpenedWorkingShift']?.id

    if (!workingShiftId) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.getDataByWorkingShift(workingShiftId) || {}

    if (data?.code === 200) {
      commit('setIsDataLoaded', true)

      return data.data
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async getDataByWorkingShiftAndUserId ({ commit }, payload) {
    commit('setIsDataLoaded', false)

    const { userId, workingShiftId } = payload || {}
    const { data } = await api.getDataByWorkingShiftAndUserId(userId, workingShiftId) || {}

    if (data?.code === 200) {
      commit('setIsDataLoaded', true)

      return data.data
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async create ({ commit, dispatch, state }, payload) {
    commit('setIsDataLoaded', false)

    const { data } = await api.create(payload) || {}

    if (data?.code === 200) {
      const newData = state.data
      newData.unshift(data.data)

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async change ({ commit, dispatch, state }, payload) {
    commit('setIsDataLoaded', false)

    const { id, data: payloadData } = payload || {}
    const { data } = await api.change(id, payloadData) || {}

    if (data?.code === 200) {
      const newData = state.data.map(item => {
        if (item.id === id) {
          item = data.data
        }

        return item
      })

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async returnSales ({ commit, dispatch, state }, id) {
    commit('setIsDataLoaded', false)

    const { data } = await api.returnSales(id) || {}

    if (data?.code === 200) {
      const newData = state.data.map(item => {
        if (item.id === id) {
          item = data.data
        }

        return item
      })

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async remove ({ commit, dispatch, state }, payload) {
    commit('setIsDataLoaded', false)

    const { data } = await api.remove(payload) || {}

    if (data?.code === 200) {
      const newData = state.data.filter(({ id }) => id !== payload )

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  }
}