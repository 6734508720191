export default {

  getIsDataLoadedSales (state) {
    return state.isDataLoadedSales
  },
  getIsDataLoadedSalesToOneMountPrice (state) {
    return state.isDataLoadedSalesToOneMountPrice
  },
  getIsDataLoadedSalesToProducts (state) {
    return state.isDataLoadedSalesToProducts
  },
  getIsDataLoadedPurchasePriseByDate (state) {
    return state.isDataLoadedPurchasePriseByDate
  },
  getIsDataLoadedSumForTypePayByDate (state) {
    return state.isDataLoadedSumForTypePayByDate
  },
  getDataSales (state) {
    return state.sales || {}
  },
  getDataSalesToOneMountPrice (state) {
    return state.salesToOneMountPrice || {}
  },
  getDataSalesToProducts (state) {
    return state.salesToProducts || {}
  },
  getDataPurchasePriseByDate (state) {
    return state.purchasePriseByDate || {}
  },
  getDataSumForTypePayByDate (state) {
    return state.sumForTypePayByDate || {}
  }
}