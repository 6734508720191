export default {
  getMainData (state) {
    return state.mainData || {}
  },
  getWorkingShiftData (state) {
    return state.workingShiftData || {}
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  }
}