export default {
  getData (state) {
    return state.data
  },
  getTitleById (state) {
    const data = state.data

    return data.reduce((acc, item) => {
      acc[item.id] = item.title

      return acc
    }, {})
  }
}