const state = {
  sales: {},
  salesToOneMountPrice: {},
  salesToProducts: {},
  purchasePriseByDate: {},
  sumForTypePayByDate: {},
  isDataLoadedSales: false,
  isDataLoadedSalesToOneMountPrice: false,
  isDataLoadedSalesToProducts: false,
  isDataLoadedPurchasePriseByDate: false,
  isDataLoadedSumForTypePayByDate: false,
  lastDataUpdated: 0
}

export default state