import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
// import { ONE_HOUR } from '@/constants/time'

// const DEFAULT_TYPE = 'working-shift'
// const DEFAULT_DATE = [moment().subtract(1, 'months').format(), moment().format()]

export default {
  // async loadDataSales ({ commit, state }, { force = false } = {}) {
  async loadDataSales ({ commit }) {
    // if (!force
    //   && state.lastDataUpdated !== 0
    //   && state.data.length
    //   && Date.now() - state.lastDataUpdated < ONE_HOUR
    // ) {
    //   return
    // }

    commit('setIsDataLoadedSales', false)

    const { data } = await api.loadDataSales() || {}

    if (data?.code === 200) {
      commit('setDataSales', data.data)
      // commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoadedSales', true)
  },
  // async loadDataSalesToOneMountPrice ({ commit, state }, { force = false } = {}) {
  async loadDataSalesToOneMountPrice ({ commit }) {
    // if (!force
    //   && state.lastDataUpdated !== 0
    //   && state.data.length
    //   && Date.now() - state.lastDataUpdated < ONE_HOUR
    // ) {
    //   return
    // }

    commit('setIsDataLoadedSalesToOneMountPrice', false)

    const { data } = await api.loadDataSalesToOneMountPrice() || {}

    if (data?.code === 200) {
      commit('setDataSalesToOneMountPrice', data.data)
      // commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoadedSalesToOneMountPrice', true)
  },
  // async loadDataSalesToProducts ({ commit, state }, { force = false } = {}) {
  async loadDataSalesToProducts ({ commit }) {
    // if (!force
    //   && state.lastDataUpdated !== 0
    //   && state.data.length
    //   && Date.now() - state.lastDataUpdated < ONE_HOUR
    // ) {
    //   return
    // }

    commit('setIsDataLoadedSalesToProducts', false)

    const { data } = await api.loadDataSalesToProducts() || {}

    if (data?.code === 200) {
      commit('setDataSalesToProducts', data.data)
      // commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoadedSalesToProducts', true)
  },
  async loadDataPurchasePriseByDate ({ commit }, date) {
    // if (!force
    //   && state.lastDataUpdated !== 0
    //   && state.data.length
    //   && Date.now() - state.lastDataUpdated < ONE_HOUR
    // ) {
    //   return
    // }

    commit('setIsDataLoadedPurchasePriseByDate', false)

    const { data } = await api.loadDataPurchasePriseByDate(date) || {}

    if (data?.code === 200) {
      commit('setDataPurchasePriseByDate', data.data)
      // commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoadedPurchasePriseByDate', true)
  },
  async loadDataSumForTypePayByDate ({ commit }, date) {
    // if (!force
    //   && state.lastDataUpdated !== 0
    //   && state.data.length
    //   && Date.now() - state.lastDataUpdated < ONE_HOUR
    // ) {
    //   return
    // }

    commit('setIsDataLoadedSumForTypePayByDate', false)

    const { data } = await api.loadDataSumForTypePayByDate(date) || {}

    if (data?.code === 200) {
      commit('setDataSumForTypePayByDate', data.data)
      // commit('setLastDataUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoadedSumForTypePayByDate', true)
  }
}