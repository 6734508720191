<template>
  <div class="wrapper-bars">
    <header class="header">
      <div
        class="header__left"
      >
        <v-icon
          class="margin-right-10"
          color="#fff"
          style="cursor: pointer"
          @click="changeIsCollapse(!isCollapse)"
        >mdi-menu</v-icon>

          <!--          <i v-if="isCollapse" class="el-icon-s-unfold" />-->
<!--          <i v-else class="el-icon-s-fold" />-->

        <router-link
          to="/"
          class="header_title-block"
        >
          <h3 class="title">Мой магазин</h3>
          <h4 class="title">&nbsp;-&nbsp;{{ nameActiveShop }}</h4>
          <h6 class="subtitle" v-if="title">&nbsp;|&nbsp;{{ title }}</h6>
        </router-link>

        <!--        <div-->
        <!--          class="d-flex justify-space-between top-bar_links"-->
        <!--        >-->
        <!--          <ul>-->
        <!--            <li-->
        <!--              v-for="item in TOP_BAR_ITEMS"-->
        <!--              :key="item.id"-->
        <!--            >-->
        <!--              <router-link-->
        <!--                class="top-bar_links-item"-->
        <!--                :to="item.url"-->
        <!--                active-class="top-bar_links-item-active"-->
        <!--                exact-->
        <!--              >{{ item.name }}</router-link>-->
        <!--            </li>-->
        <!--          </ul>-->
        <!--        </div>-->
      </div>

      <div class="header__right">
        <span
          v-if="!isMobile()"
          class="header-action"
          @click="openModalBugReport"
        >
          Сообщить об ошибке
        </span>

        <span
          class="header-action"
          @click="logoutWrapper"
        >
          Выход
        </span>
      </div>
    </header>

    <navigation
      :isCollapse="isCollapse"
      @changeCollapse="changeIsCollapse"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Navigation from '@/components/bars/navigation'
import { isMobile } from '@/helper'
import store from '@/store'
import { LIST_SHOPS } from '@/constants/global'

// Вынести в модуль
const CLEAR_DATA_MODULES = [
  'categories',
  'products',
  'onlineOrders',
  'user',
  'users',
  'settings',
  'sales',
  'info',
  'workingShift',
  'cashOperation',
  'operatingSchedule',
  'statistic',
  'history'
]

export default {
  name: 'TopBar',
  components: {
    Navigation
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isCollapse: true
    }
  },
  computed: {
    nameActiveShop () {
      return LIST_SHOPS.find(({ id }) => Number(id) === Number(localStorage.getItem('shopId'))).name
    }
  },
  methods: {
    ...mapActions('user', ['logout']),
    ...mapActions('modal', ['openModal']),
    async logoutWrapper () {
      await this.logout()

      for (let i = 0; i < CLEAR_DATA_MODULES.length; i++) {
        const module = CLEAR_DATA_MODULES[i]

        await store.commit(`${module}/clearState`)
      }
    },
    isMobile,
    changeIsCollapse (val) {
      this.isCollapse = val
    },
    openModalBugReport () {
      this.openModal({
        name: 'bug-report-modal',
        title: 'Отправить сообщение об ошибке'
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
