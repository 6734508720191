import moment from 'moment'

export default {
  getData (state) {
    const data = state.data

    if (!data.length) {
      return []
    }

    return data.map((item) => {
      return {
        ...item,
        openingAt: moment(item.openingAt).format('dd - DD.MM.YYYY HH:mm'),
        closingAt: item.closingAt ? moment(item.closingAt).format('dd - DD.MM.YYYY HH:mm') : ''
      }
    })
  },
  getRawData (state) {
    return state.data
  },
  getOpenedWorkingShift (state) {
    return state.data.filter(({ isOpened }) => isOpened).at(0)
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  }
}