import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueBus from 'vue-bus'
import vuetify from './plugins/vuetify'
import VueMoment from 'vue-moment'
import('../node_modules/vuetify/dist/vuetify.min.css')

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

locale.use(lang)

Vue.config.productionTip = false

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    this.event = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', this.event)
  },
  unbind: function () {
    document.body.removeEventListener('click', this.event)
  }
})

let moment = require('moment')
moment.locale("ru")

Vue.use(VueMoment, { moment })
Vue.use(VueBus)
Vue.use(ElementUI)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app")
