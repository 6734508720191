import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'
import {
  URL_LOGOUT,
  URL_AUTH,
  URL_GET_DATA
} from '../../constants'

export const loadData = async (userId) => {
  return axiosInstance.get(`${getApiUrl()}${URL_GET_DATA}/${userId}`)
}

export const auth = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL_AUTH}`, {
    data: payload
  })
}

export const logout = async (id) => {
  return axiosInstance.post(`${getApiUrl()}${URL_LOGOUT}`, {
    data: { id }
  })
}