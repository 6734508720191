import { Notification } from 'element-ui'

export const showNotifyError = (message) => {
  Notification.error({
    title: 'Ошибка',
    message: message
  })
}

export const showNotifySuccess = (title, message) => {
  Notification.success({
    title: title,
    message: message
  })
}