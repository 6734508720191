import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'
import { URL } from '../../constatns'

export const loadData = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}`)
}

export const getInfo = async (id) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/info/${id}`)
}

export const open = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}/open`)
}

export const close = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}/close`)
}

export const remove = async (id) => {
  return axiosInstance.delete(`${getApiUrl()}${URL}/${id}`)
}
