import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'
import { URL } from '../../constatns'

export const loadData = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}/by-date`, {
    data: payload
  })
}

export const getDataByWorkingShiftAndUserId = async (userId, workingShiftId) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/user/working-shift/${userId}/${workingShiftId}`)
}

export const getDataByWorkingShift = async (workingShiftId) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/working-shift/${workingShiftId}`)
}

export const getDataById = async (id) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/full-data/${id}`)
}

export const create = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}`, {
    data: payload
  })
}

export const returnSales = async (id) => {
  return axiosInstance.get(`${getApiUrl()}${URL}/return/${id}`)
}

export const change = async (id, payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}/change/${id}`, {
    data: payload
  })
}

export const remove = async (id) => {
  return axiosInstance.delete(`${getApiUrl()}${URL}/${id}`)
}
