import Vue from 'vue'
import Vuex from 'vuex'

import categories from './categories'
import products from './products'
import modal from './modal'
import onlineOrders from './onlineOrders'
import user from './user'
import users from './users'
import settings from './settings'
import sales from './sales'
import info from './info'
import bugReport from './bugReport'
import workingShift from './workingShift'
import cashOperation from './cashOperation'
import operatingSchedule from './operatingSchedule'
import statistic from './statistic'
import history from './history'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    categories,
    products,
    modal,
    onlineOrders,
    user,
    users,
    settings,
    sales,
    info,
    bugReport,
    workingShift,
    cashOperation,
    operatingSchedule,
    statistic,
    history
  }
})


