import { mapGetters } from 'vuex'
import names from '@/constants/names'

const pageMethods = {
  computed: {
    ...mapGetters('user', ['isAccessAllowed']),
    nameActivePage () {
      return this.$route.name || ''
    },
    nameActivePageForMethod () {
      return names.activePage[this.nameActivePage] || this.nameActivePage
    },
    getModalName () {
      return names.modalNames[this.nameActivePageForMethod] || ''
    },
    getPageTitle () {
      return names.pageNames[this.nameActivePageForMethod] || ''
    },
    getModalNameSales () {
      return names.modalNames?.sales || ''
    }
  }
}

export default pageMethods