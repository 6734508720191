import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { THREE_MINUTES } from '@/constants/time'

export default {
  async loadData ({ commit, state }) {
    if (state.lastDateUpdated !== 0 && state.data.length && Date.now() - state.lastDateUpdated < THREE_MINUTES) {
      return
    }

    const { data } = await api.loadData() || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  },
  async loadLastBalance ({ commit }) {
    const { data } = await api.loadLastBalance() || {}

    if (data?.code === 200) {
      commit('setLastBalance', data.data?.balance)
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  },
  async create ({ commit, dispatch, state }, payload) {
    const { data } = await api.create(payload) || {}

    if (data?.code === 200) {
      const newData = state.data
      newData.unshift(data.data)

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  },
  async change ({ commit, dispatch, state }, payload) {
    const { id, data: payloadData } = payload || {}
    const { data } = await api.change(id, payloadData) || {}

    if (data?.code === 200) {
      const newData = state.data.map(item => {
        if (item.id === id) {
          item = {
            ...item,
            ...payloadData
          }
        }

        return item
      })

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  },
  async remove ({ commit, dispatch, state }, payload) {
    const { data } = await api.remove(payload) || {}

    if (data?.code === 200) {
      const newData = state.data.filter(({ id }) => id !== payload )

      commit('setData', newData)
      dispatch('modal/closeModal', null, { root: true })
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  }
}