export default {
  setData (state, payload) {
    if (!payload) {
      return
    }

    state.data = payload
  },
  setLastBalance (state, payload) {
    state.lastBalance = payload
  },
  setLastDateUpdated (state) {
    state.lastDateUpdated = new Date().getTime()
  },
  clearState (state) {
    state.data = []
    state.lastBalance = 0
    state.lastDateUpdated = 0
  }
}