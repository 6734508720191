<template>
  <el-menu
    class="navigation"
    :collapse="isCollapse"
  >
    <div
      class="navigation-user"
      :class="getClass"
    >
      <el-avatar>{{ getInitials }}</el-avatar>

      <div
        v-if="!isCollapse"
        class="navigation-user-data"
      >
        <span class="navigation-user-data-fullname">{{ getFullName }}</span>
        <span class="navigation-user-data-role">{{ role }}</span>
      </div>
    </div>

    <template
      v-for="(link, index) in getLinks"
    >
      <el-submenu
        v-if="link.subLinks"
        :key="index"
        :index="String(index)"
        :class="{ 'active-class': activePage === link.code }"
      >
        <template slot="title">
<!--          <i class="el-icon-location" />-->
          <v-icon
            class="margin-right-5"
            :color="getColor(link)"
          >{{ link.icon }}</v-icon>

          <span slot="title">{{ link.title }}</span>
        </template>

        <el-menu-item-group
          v-for="(subLink, subLinkIndex) in link.subLinks"
          :key="subLink.code"
        >
          <el-menu-item
            :index="`${index}-${subLinkIndex}`"
            @click="handleClick(subLink.to)"
            :class="{ 'active-class': activePage === subLink.code }"
          >{{ subLink.title }}</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item
        v-else
        :key="index"
        :index="String(index)"
        @click="handleClick(link.to)"
        :class="{ 'active-class': activePage === link.code }"
      >
<!--        <i class="el-icon-menu" />-->
        <v-icon
          class="margin-right-5"
          :color="activePage === link.code ? '#1f72a2' : ''"
        >{{ link.icon }}</v-icon>

        <span slot="title">{{ link.title }}</span>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { mapGetters } from 'vuex'
// import { isMobile } from '@/helper'

export default {
  name: 'Navigation',
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      rail: true
    }
  },
  computed: {
    ...mapGetters('settings', ['getLinks']),
    ...mapGetters('user', ['getFullName', 'getInitials', 'getUserRole']),
    ...mapGetters('settings', ['getDefaultValues']),
    role () {
      return this.getDefaultValues?.role?.filter(el => el.code === this.getUserRole[0])[0]?.name
    },
    activePage () {
      return this.$route.name
    },
    getClass () {
      return this.isCollapse
        ? 'navigation-user__collapse'
        : ''
    }
  },
  methods: {
    getColor (link) {
      return this.activePage === link.code
        ? '#1f72a2'
        : ''
    },
    handleClick (to) {
      if (to === this.$route.path) {
        return
      }

      this.$router.push(to).catch()
      this.$emit('changeCollapse', true)
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />
