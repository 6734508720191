export default {
  setMainData (state, payload) {
    if (!payload) {
      return
    }

    state.mainData = payload
  },
  setWorkingShiftData (state, payload) {
    if (!payload) {
      return
    }

    state.workingShiftData = payload
  },
  setIsDataLoaded (state, payload) {
    state.isDataLoaded = payload
  },
  setLastMainDateUpdated (state) {
    state.lastMainDateUpdated = new Date().getTime()
  },
  setLastWorkingShiftDateUpdated (state) {
    state.lastWorkingShiftDateUpdated = new Date().getTime()
  },
  clearState (state) {
    state.mainData = {}
    state.workingShiftData = {}
    state.isDataLoaded = false
    state.lastMainDateUpdated = 0
    state.lastWorkingShiftDateUpdated = 0
  }
}