import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'
import { URL } from '../../constatns'

export const loadData = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}`)
}

export const create = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}`, {
    data: payload
  })
}

export const change = async (id, payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}/change/${id}`, {
    data: payload
  })
}

export const remove = async (id) => {
  return axiosInstance.delete(`${getApiUrl()}${URL}/${id}`)
}
