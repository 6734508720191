import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { ONE_HOUR } from '@/constants/time'

export default {
  async loadData ({ commit, state }) {
    if (state.lastDateUpdated !== 0 && state.data.length && Date.now() - state.lastDateUpdated < ONE_HOUR) {
      return
    }

    const { data } = await api.loadData() || {}

    if (data?.code === 200) {
      commit('setData', data.data)
      commit('setLastDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }
  }
}