import axiosInstance from '@/axiosInstance'
import { getApiUrl } from '@/helper'
import { URL } from '../../constatns'

export const loadDataSales = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}/sales`)
}

export const loadDataSalesToOneMountPrice = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}/sales-to-one-mount-price`)
}

export const loadDataSalesToProducts = async () => {
  return axiosInstance.get(`${getApiUrl()}${URL}/sales-to-products`)
}

export const loadDataPurchasePriseByDate = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}/purchase-prise-by-date`, {
    data: payload
  })
}

export const loadDataSumForTypePayByDate = async (payload) => {
  return axiosInstance.post(`${getApiUrl()}${URL}/sum-for-type-pay-by-date`, {
    data: payload
  })
}
