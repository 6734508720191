import { checkRole } from '@/store/user/helper'

export default {
  getData (state) {
    return state.data
  },
  getFullName (state) {
    return state.data.name
      ? `${state.data.name} ${state.data.surname}`
      : ''
  },
  getInitials (state) {
    return state.data?.name
      ? `${state.data?.name?.[0]}${state.data?.surname?.[0]}`.toUpperCase()
      : ''
  },
  isAuth (state) {
    return state.data?.id || localStorage.getItem('userId')
  },
  isAccessAllowed (state) {
    return Boolean(checkRole(state.data?.role)) || localStorage.getItem('accessAllowed') === 'admin'
  },
  getUserId (state) {
    return state.data?.id || localStorage.getItem('userId')
  },
  getUserRole (state) {
    return state.data?.role || []
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  }
}