import * as api from './api'
import { ERROR_TO_REQUEST } from '@/textErrors'
import { showNotifyError } from '@/helper/notify'
import { THREE_MINUTES } from '@/constants/time'

export default {
  async loadMainData ({ commit, state, rootGetters }, force = false) {
    if (!rootGetters['workingShift/getOpenedWorkingShift']?.id) {
      return
    }

    if (!force
      && state.lastMainDateUpdated !== 0
      && Date.now() - state.lastMainDateUpdated < THREE_MINUTES
    ) {
      return
    }

    commit('setIsDataLoaded', false)

    const { data } = await api.loadMainInfo(rootGetters['user/getUserId']) || {}

    if (data?.code === 200) {
      commit('setMainData', data.data)
      commit('setLastMainDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  },
  async loadWorkingShiftData ({ commit, state }, payload) {
    if (!payload?.force && state.lastWorkingShiftDateUpdated !== 0 && Date.now() - state.lastWorkingShiftDateUpdated < THREE_MINUTES) {
      return
    }

    commit('setIsDataLoaded', false)

    const { workingShiftId, userId } = payload
    const { data } = await api.loadWorkingShiftInfo(workingShiftId, userId) || {}

    if (data?.code === 200) {
      commit('setWorkingShiftData', data.data)
      commit('setLastWorkingShiftDateUpdated')
    } else {
      showNotifyError(data?.data?.text || ERROR_TO_REQUEST)
    }

    commit('setIsDataLoaded', true)
  }
}