import axios from 'axios'
import store from '@/store'
import { getApiUrl } from '@/helper'

const baseURL = getApiUrl()

const axiosInstance = axios.create({
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  exposedHeaders: ['set-cookie']
})


axiosInstance.interceptors.request.use((request) => {
  request.headers.set({
    'token': localStorage.getItem('token'),
    'userid': localStorage.getItem('userId')
  })

  return request
}, (error) => {
  return error
})

axiosInstance.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token')
      localStorage.removeItem('userId')
      localStorage.removeItem('accessAllowed')
      store.commit('user/clearState')

      window.location.href = '/auth'
    }

    return error.response
  }
)

export default axiosInstance