export default {
  setData (state, payload) {
    if (!payload) {
      return
    }

    state.data = payload
  },
  setLastDateUpdated (sate) {
    sate.lastDateUpdated = new Date().getTime()
  },
  clearState (state) {
    state.data = []
    state.lastDateUpdated = 0
  }
}