import moment from 'moment/moment'

export default {
  getData (state, _getters, _rootState, rootGetters) {
    const data = state.data

    const getCategoryById = (findId) => rootGetters['categories/getData'].filter(({ id }) => id === findId)[0]

    return data.map(item => {
      const price = Number(item.salePrice) ? `Скидка - ${item.salePrice}` : item.price

      return {
        ...item,
        categoriesName: getCategoryById(item.categories)?.title || 'Нет категории',
        createdAt: moment(item.createdAt).format('dd - DD.MM.YYYY HH:mm'),
        titleWithCodeCountPrice: `${item.count} : ${item.title}(${item.code}) - ${price} р.`,
      }
    })
  },
  getDataObject (_state, getters) {
    return getters.getData.reduce((acc, item) => {
      acc[item.id] = item

      return acc
    }, {})
  },
  getTitleById (state) {
    const data = state.data

    return data.reduce((acc, item) => {
      acc[item.id] = item.title

      return acc
    }, {})
  },
  getIsDataLoaded (state) {
    return state.isDataLoaded
  }
}