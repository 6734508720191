export default {
  getData (state, _getters, _rootState, rootGetters) {
    return state.data.map((item) => {
      const user = rootGetters['users/getData'].find(({ id }) => id === item.userId)

      if (!user) {
        return item
      }

      return {
        ...item,
        fullName: `${user.name} ${user.surname}`,
      }
    })
  }
}