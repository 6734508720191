export default {
  getData (state) {
    return state.data
  },
  getPermissions (state) {
    return state.data.permissions || {}
  },
  getHeaders (state) {
    return state.data.headers || {}
  },
  getFields (state) {
    return state.data.fields || {}
  },
  getDefaultValues (state) {
    return state.data.defaultValues || {}
  },
  getLinks (state) {
    return state.data.links || []
  },
  getIsAllowedPage (state, getters) {
    return (action, page) => {
      const permissions = getters.getPermissions

      return permissions[action]?.includes('all') || permissions[action]?.includes(page)
    }
  }
}